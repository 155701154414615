import React from "react";
import "./unsubscribe.scss";
import rightImage from "../../assets/unsubscribe/exit.svg";
import { Form, Input, Modal } from "antd";
import { useTranslation } from "react-i18next";
import useScrollToTop from "../customHook/useScrollToTop";
import {
  renderRTLClass,
  testRenderRTL,
  trimValue,
} from "../../utils/commonUtils";
import rtlUnsubscribeImg from "../../assets/unsubscribe/rtl_unsubscribe__img.svg";
const UnsubscribeComponent = (props) => {
  const {
    handleUnsubscribe,
    setEmail,
    error,
    handleOpenModal,
    successPopup,
    setOpenModal,
    openModal,
    unsubscribeResult,
    isLoading,
    email,
  } = props;
  useScrollToTop();
  const { t } = useTranslation();
  const handleCloseModal = () => {
    setOpenModal(false);
  };
  return (
    <div className="unsubscribe-main-section">
      <div className="back-grad-title">
        <div className="pricing-main-title">{t("MENU_UNSUBSCRIBE")}</div>
      </div>
      <div className="unsubscribe-container">
        <div className="wrap-unsubscribe">
          <div className="df-unsubscribe">
            <div className="unsubscribe-left-contant">
              <div className="df-leftcontant">
                <div className="unsubscribe-confirmation">
                  {t("UNSUBSCRIBE_PAGE_CONFIRM_TITLE")}
                </div>

                <Form>
                  <div className="unsubscribe-input-email">
                    <label>{t("LOGIN_EMAIL_LABEL")}</label>
                    <Input
                      name="email"
                      onChange={(e) =>
                        setEmail(trimValue(e.target.value).toLowerCase())
                      }
                      className={error && "errorInput"}
                      placeholder="hello@mail.com"
                      autoComplete="email"
                      aria-label="Email"
                      inputMode="email"
                      autoCapitalize="none"
                      value={email}
                    />
                    {error && (
                      <p style={{ color: "red", margin: "0" }}>{t(error)}</p>
                    )}
                  </div>
                  <div className="unsubscribe-email-submit">
                    <button
                      onClick={handleOpenModal}
                      type="submit"
                      className="hl_cta_wrap"
                      style={{ marginTop: "30px" }}
                      disabled={isLoading}
                    >
                      {t("UNSUBSCRIBE_PAGE_SUBMIT")}
                    </button>
                  </div>
                </Form>
              </div>
            </div>
            <div className="unsubscribe-right-contant">
              <div className="right-image">
                <img
                  src={testRenderRTL() ? rtlUnsubscribeImg : rightImage}
                  alt="Exit"
                  width={450}
                  height={450}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <Modal
        open={openModal}
        onCancel={handleCloseModal}
        centered
        okButtonProps={{ style: { display: "none" } }}
        cancelButtonProps={{ style: { display: "none" } }}
        className={`${renderRTLClass()} home__unsubscribe_weapper`}
      >
        <div className="login-modal-section">
          <div className="login-modal-title">
            <p>{t("MENU_UNSUBSCRIBE")}</p>
          </div>
          <div className="unsubscribe-modal-body">
            <div className="unsubscribe-text">
              {t("SETTING_UNSUBSCRIBE_TEXT")}
            </div>
            <div className="submit-btn-modal">
              <button onClick={handleUnsubscribe} disabled={isLoading}>
                {t("SETTING_UNSUBSCRIBE_SUBMIT_BUTTON")}
              </button>
            </div>
          </div>
        </div>
      </Modal>
      <Modal
        open={successPopup}
        centered
        okButtonProps={{ style: { display: "none" } }}
        cancelButtonProps={{ style: { display: "none" } }}
        className={renderRTLClass()}
      >
        <div className="login-modal-section">
          <div className="login-modal-title">
            <p>{t("MENU_UNSUBSCRIBE")}</p>
          </div>
          <div className="unsubscribe-modal-body">
            <div className="unsubscribe-text">{unsubscribeResult?.message}</div>
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default UnsubscribeComponent;
