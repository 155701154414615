/* global gtag */
import React, { useState } from "react";
import { register } from "../../redux/slice/registerSlice";
import { connect } from "react-redux";
import { trailSubscription } from "../../redux/slice/trailSubscriptionSlice";
import { createSubscription } from "../../redux/slice/createSubscriptionSlice";
import { useEffect } from "react";
import { standardSubscription } from "../../redux/slice/standardSubscriptionSlice";
import { settingBanIp } from "../../redux/slice/settingBanIpSlice";
import SignUpComponent from "./signUp";
import { paymentIntent } from "../../redux/slice/paymentIntentSlice";
import { subscriptionCreate } from "../../redux/slice/subscriptionCreateSlice";
import { Cookies } from "react-cookie";
import { savegclid } from "../../redux/slice/savegclidSlice";
import { getGclidDetails } from "../../redux/slice/getGclidDetailsSlice";
import { gclidCheck } from "../../redux/slice/gclidCheckSlice";
import { googleOauth2 } from "../../redux/slice/googleOauth2Slice";
import { locatePhoneNumberIPQS } from "../../redux/slice/locatePhoneNumberIPQSlice";
import { useSearchParams } from "react-router-dom";
import { createSubscriptionDebug } from "../../redux/slice/createSubscriptionDebugSlice";
import { paymentIntentV7 } from "../../redux/slice/paymentIntentV7Slice";
import { getSendDetails } from "../../redux/slice/getSendDetailsSlice";
import { ga_key } from "../../environment";

const SignUp = (props) => {
  const {
    callRegisterData,
    callTrailSubscription,
    callcreateSubscription,
    productList,
    createSubscription,
    trailSubscription,
    callStandardSubscription,
    standardSubscription,
    subscription,
    page,
    callSettingBanIp,
    callpaymentIntent,
    callsubscriptionCreate,
    callgclidData,
    getGclidDetails,
    callGclidCheck,
    ipData,
    callGoogleOauth2,
    locatePhoneNumberIPQS,
    callLocatePhoneNumberIPQS,
    callSubscriptionDebug,
    sendDetailsData,
    callpaymentIntentV7,
    getSendDetails,
  } = props;
  const [searchparams] = useSearchParams();
  const searchNumber = Object.fromEntries([...searchparams]);
  const pageVersion = page === "track" || page === "trace" ? "b" : "w";
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const cookie = new Cookies();
  const lang = cookie.get("lang");
  const cur = cookie.get("currency");
  const [ip, setIp] = useState();
  const [gclid, setgclid] = useState();
  const [data, setData] = useState({
    email: "",
    currency: "",
    language: "",
  });
  useEffect(() => {
    gtag("event", "page_view", {
      page_version: pageVersion,
      send_to: ga_key,
    });
    // eslint-disable-next-line
  }, []);
  useEffect(() => {
    setData((prev) => ({
      ...prev,
      currency: cur ? cur : "usd",
      language: lang ? lang : "en",
    }));
    // eslint-disable-next-line
  }, [lang, cur]);
  // Stripe Payment Flow
  useEffect(() => {
    fetch("https://api.ipify.org?format=json")
      .then((response) => response.json())
      .then((data) => setIp(data.ip))
      .catch((error) => console.log(error));
  }, []);
  useEffect(() => {
    if (ip) {
      getGclidDetailsData();
    }
    // eslint-disable-next-line
  }, [ip]);
  const getGclidDetailsData = async () => {
    const res = await getGclidDetails(ip);
    if (res.type === "getGclidDetails/fulfilled") {
      setgclid(res.payload.data);
    }
  };
  useEffect(() => {
    const { D, n } = searchNumber;
    const cleanNumber = (number) => number.replace(/\D/g, "");

    if (D && n) {
      const cleanD = cleanNumber(D);
      const cleanN = cleanNumber(n);

      if (cleanD && cleanN) {
        try {
          const data = {
            phone_number_prefix: cleanD,
            phone_number: cleanN,
          };
          callLocatePhoneNumberIPQS(data);
        } catch (error) {
          console.error("Invalid phone number:", error);
        }
      }
    }
  // eslint-disable-next-line
  }, [searchNumber.D, searchNumber.n]);

  return (
    <SignUpComponent
      setData={setData}
      productList={productList}
      callRegisterData={callRegisterData}
      data={data}
      callTrailSubscription={callTrailSubscription}
      createSubscription={callcreateSubscription}
      callStandardSubscription={callStandardSubscription}
      standardSubscription={standardSubscription}
      subscriptionData={createSubscription}
      trailSubscription={trailSubscription}
      subscription={subscription}
      page={page}
      callSettingBanIp={callSettingBanIp}
      callsubscriptionCreate={callsubscriptionCreate}
      callpaymentIntent={callpaymentIntent}
      callgclidData={callgclidData}
      gclid={gclid}
      callGclidCheck={callGclidCheck}
      ipData={ipData}
      googleOauth2={callGoogleOauth2}
      locatePhoneNumberIPQS={locatePhoneNumberIPQS}
      callSubscriptionDebug={callSubscriptionDebug}
      sendDetailsData={sendDetailsData}
      callpaymentIntentV7={callpaymentIntentV7}
      getSendDetails={getSendDetails}
    />
  );
};
const mapStateToProps = (state) => {
  return {
    registerData: state.registerData,
    productList: state.getProductList.productListData,
    createSubscription: state.createSubscription,
    trailSubscription: state.trailSubscription,
    standardSubscription: state.standardSubscription,
    ipData: state.getIPIfy.getIPIfyData,
    productListSingle: state.getProductListSingle.productListSingleData,
    locatePhoneNumberIPQS: state?.locatePhoneNumberIPQS?.result?.data?.data,
    sendDetailsData: state.sendDetailsData.getSendDetailsData,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    callRegisterData: (data) => dispatch(register(data)),
    callTrailSubscription: (data) => dispatch(trailSubscription(data)),
    callcreateSubscription: (data) => dispatch(createSubscription(data)),
    callStandardSubscription: (data) => dispatch(standardSubscription(data)),
    callSettingBanIp: (data) => dispatch(settingBanIp(data)),
    callpaymentIntent: (data) => dispatch(paymentIntent(data)),
    callsubscriptionCreate: (data) => dispatch(subscriptionCreate(data)),
    callgclidData: (data) => dispatch(savegclid(data)),
    getGclidDetails: (id) => dispatch(getGclidDetails(id)),
    callGclidCheck: (data) => dispatch(gclidCheck(data)),
    callGoogleOauth2: (data) => dispatch(googleOauth2(data)),
    callLocatePhoneNumberIPQS: (data) => dispatch(locatePhoneNumberIPQS(data)),
    callSubscriptionDebug: (data) => dispatch(createSubscriptionDebug(data)),
    callpaymentIntentV7: (data) => dispatch(paymentIntentV7(data)),
    getSendDetails: (data) => dispatch(getSendDetails(data)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(SignUp);
