/* global gtag */
import React, { useEffect, useState } from "react";
import SettingsComponent from "./settings";
import { connect } from "react-redux";
import { unsubscribe } from "../../redux/slice/unsubscribeSlice";
import Notify from "../common/Notify/notify";
import { updateSetting } from "../../redux/slice/updateSettingSlice";
import { Cookies, useCookies } from "react-cookie";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { authenticate } from "../../redux/slice/authenticateSlice";
import { updateUserLanguage } from "../../redux/slice/updateUserLanguageSlice";
import { subscriptionList } from "../../redux/slice/subscriptionListSlice";

const Settings = (props) => {
  const {
    callunsubscribe,
    authenticate,
    callupdateSetting,
    unsubscribeResult,
    getAuthenticate,
    callUpdateUserLanguage,
    callSubscriptionList,
    getSubscriptionListList,
    productList,
  } = props;
  const [email, setEmail] = useState("");
  const [loading, setLoading] = useState(false);
  const [details, setDetails] = useState({
    first_name: "",
    last_name: "",
    address: "",
    country: "",
    city: "",
    zipcode: 0,
  });
  const [authData, setAuthData] = useState({ email: "", createdAt: "" });
  const cookies = new Cookies();
  const { t } = useTranslation();
  const navigate = useNavigate();
  // eslint-disable-next-line
  const [token, setToken, removeToken] = useCookies(["token"]);
  // eslint-disable-next-line
  const [roles, setRole, removeRole] = useCookies(["role"]);
  const lang = cookies.get("lang");
  useEffect(() => {
    if (authenticate.authenticateData) {
      setEmail(authenticate.authenticateData.data.email);
      setDetails((prev) => ({
        ...prev,
        first_name: authenticate.authenticateData.data.first_name,
        last_name: authenticate.authenticateData.data.last_name,
        address: authenticate.authenticateData.data.address,
        country: authenticate.authenticateData.data.country,
        city: authenticate.authenticateData.data.city,
        zipcode: authenticate.authenticateData.data.zipcode,
      }));
      setAuthData((prev) => ({
        ...prev,
        email: authenticate.authenticateData.data.email,
        createdAt: authenticate.authenticateData.data.createdAt,
      }));
    }
  }, [authenticate.authenticateData]);
  const [successPopup, setSuccessPopup] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const handleUnsubscribe = async () => {
    setLoading(true);
    const userEmail = cookies.get("email");
    const unsubscribed_email = email ? email : userEmail;
    const data = {
      email: unsubscribed_email?.toLowerCase(),
    };
    const result = await callunsubscribe(data);
    if (result.type === "unsubscribe/fulfilled") {
      setOpenModal(false);
      setSuccessPopup(true);
      if (result.payload.data.success) {
        Notify("success", t("NOTIFY_UNSUBSCRIBE_PAGE"), "");
        gtag("event", "unsubscribe_email", {
          unsubscribed_email: unsubscribed_email,
          user_id: result.payload.data.user_id,
        });
      }
      setTimeout(() => {
        removeToken(["token"]);
        removeRole(["role"]);
        const cookieKeys = Array.isArray(cookies.getAll())
          ? cookies.getAll()
          : Object.keys(cookies.getAll());
        cookieKeys.forEach((key) => {
          cookies.remove(key, { path: "/" });
        });
        navigate(`/${lang}`);
        window.location.reload("/");
      }, 10000);
    } else {
      Notify("error", result.error.message, "");
    }
    setLoading(false);
  };
  const handleChangeDetails = (event) => {
    const { name, value } = event.target;
    const trimmedValue =
      typeof value === "string" ? value.replace(/^\s+/g, "") : value;
    if (trimmedValue.length < 40) {
      setDetails((prev) => ({
        ...prev,
        [name]: trimmedValue,
      }));
    } else if (name === "address") {
      if (trimmedValue.length < 256) {
        setDetails((prev) => ({
          ...prev,
          [name]: trimmedValue,
        }));
      }
    }
    if (trimmedValue) {
      setError((prev) => ({
        ...prev,
        [name]: "",
      }));
    } else {
      if (name === "first_name") {
        setError((prev) => ({
          ...prev,
          first_name: t("BILLING_FIRST_NAME_REQUIRED"), // Use the appropriate error message here
        }));
      }
      if (name === "last_name") {
        setError((prev) => ({
          ...prev,
          last_name: t("BILLING_LAST_NAME_REQUIRED"),
        }));
      }
      if (name === "address") {
        setError((prev) => ({
          ...prev,
          address: t("BILLING_ADDRESS_REQUIRED"),
        }));
      }
      if (name === "country") {
        setError((prev) => ({
          ...prev,
          country: t("BILLING_COUNTRY_REQUIRED"),
        }));
      }
      if (name === "city") {
        setError((prev) => ({
          ...prev,
          city: t("BILLING_CITY_REQUIRED"),
        }));
      }
      if (name === "zipcode") {
        setError((prev) => ({
          ...prev,
          zipcode: t("BILLING_POSTAL_CODE_REQUIRED"),
        }));
      }
    }
  };

  const handleChangeCountry = (event) => {
    setDetails((prev) => ({
      ...prev,
      country: event.target.value,
    }));
  };
  const [error, setError] = useState({
    first_name: "",
    last_name: "",
    address: "",
    country: "",
    city: "",
    zipcode: "",
  });
  const handleUpdateDetails = async () => {
    setLoading(true);
    let authError = {};
    if (!details.first_name) {
      authError.first_name = t("BILLING_FIRST_NAME_REQUIRED");
    }
    if (!details.last_name) {
      authError.last_name = t("BILLING_LAST_NAME_REQUIRED");
    }
    if (!details.address) {
      authError.address = t("BILLING_ADDRESS_REQUIRED");
    }
    if (!details.country) {
      authError.country = t("BILLING_COUNTRY_REQUIRED");
    }
    if (!details.city) {
      authError.city = t("BILLING_CITY_REQUIRED");
    }
    if (!details.zipcode) {
      authError.zipcode = t("BILLING_POSTAL_CODE_REQUIRED");
    }
    if (Object.keys(authError).length !== 0) {
      setError(authError);
      setLoading(false);
      return;
    }
    const result = await callupdateSetting(details);
    if (result.type === "updateSetting/fulfilled") {
      Notify("success", t("NOTIFY_UPDATE_SETTINGS"), "");
      getAuthenticate();
    } else {
      Notify("error", result?.payload?.data?.message, "");
    }
    setLoading(false);
  };
  // Reactive Payment
  useEffect(() => {
    callSubscriptionList();
    if (authenticate && authenticate.authenticateData) {
      const pastDuoStatus =
        authenticate.authenticateData.data.subscription.status;
      if (pastDuoStatus === "past_due") {
        navigate(`/${lang}/dash`);
      }
    }
  // eslint-disable-next-line
  }, [authenticate.authenticateData]);
  return (
    <SettingsComponent
      handleUnsubscribe={handleUnsubscribe}
      handleChangeDetails={handleChangeDetails}
      details={details}
      handleUpdateDetails={handleUpdateDetails}
      handleChangeCountry={handleChangeCountry}
      successPopup={successPopup}
      setOpenModal={setOpenModal}
      openModal={openModal}
      loading={loading}
      unsubscribeResult={unsubscribeResult.unsubscribeData}
      error={error}
      authenticate={authenticate}
      callUpdateUserLanguage={callUpdateUserLanguage}
      subscriptionData={getSubscriptionListList.subscriptionData}
      productList={productList}
      authData={authData}
    />
  );
};

const mapStateToProps = (state) => {
  return {
    authenticate: state.authenticate,
    unsubscribeResult: state.unsubscribe,
    getSubscriptionListList: state.getSubscriptionListList,
    productList: state.getProductList.productListData,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    callunsubscribe: (data) => dispatch(unsubscribe(data)),
    callupdateSetting: (data) => dispatch(updateSetting(data)),
    getAuthenticate: () => dispatch(authenticate()),
    callSubscriptionList: () => dispatch(subscriptionList()),
    callUpdateUserLanguage: (data) => dispatch(updateUserLanguage(data)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Settings);
