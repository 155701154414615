import React from "react";
import "./contact.scss";
import rightImage from "../../assets/contact/Support_center_02.jpg";
import { useTranslation } from "react-i18next";
import useScrollToTop from "../customHook/useScrollToTop";

const ContactComponent = () => {
  useScrollToTop();
  const { t } = useTranslation();
  return (
    <div className="contact-main-section">
      <div className="back-grad-title">
        <div className="pricing-main-title">{t("CONTACT_US_TITLE")}</div>
      </div>
      <div className="contact-bg">
        <div className="contact-container">
          <div className="df-contact">
            <div className="contact-left-contant">
              <div className="df-leftcontan">
                <div className="box-contact">
                  <div className="box-contact-title">
                    {t("CONTACT_US_EMAIL")}
                  </div>
                  <div
                    className="box-contant-text"
                    dangerouslySetInnerHTML={{
                      __html: t("CONTACT_US_TEXT"),
                    }}
                  ></div>
                </div>
              </div>
            </div>
            <div className="contact-right-contant">
              <div className="right-image">
                <img src={rightImage} alt="Exit" width={450} height={450} />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ContactComponent;
