import React from "react";
import "./cookiesPolicy.scss";
import { useTranslation } from "react-i18next";
import { Markup } from "interweave";
import useScrollToTop from "../customHook/useScrollToTop";

const browserURL = {
  Edge: "https://www.google.com/url?q=http://windows.microsoft.com/es-es/windows-10/edge-privacy-faq&sa=D&ust=1491479807280000&usg=AFQjCNExWkpVolVZ5WmGkhMrRq4WoOOmZg",
  "Google Chrome":
    "https://www.google.com/url?q=https://support.google.com/chrome/answer/95647?hl=es&sa=D&ust=1491479807282000&usg=AFQjCNHVfPwTJqjehsKZwLpTTdx3t6PtEA",
  "Google Android":
    "https://www.google.com/url?q=https://support.google.com/chrome/answer/2392971?hl=es&sa=D&ust=1491479807283000&usg=AFQjCNFT5Xzp_9_45YIQ-0GR0UuV8xLILQ",
  "Internet Explorer 7 y 8":
    "https://www.google.com/url?q=http://windows.microsoft.com/es-es/windows-vista/block-or-allow-cookies&sa=D&ust=1491479807284000&usg=AFQjCNFT8rPLRd8ZdCrmxDNkLAv8RhYRTg",
  "Internet Explorer 9":
    "https://www.google.com/url?q=http://windows.microsoft.com/es-es/windows7/how-to-manage-cookies-in-internet-explorer-9&sa=D&ust=1491479807285000&usg=AFQjCNF9oECm0dg_3XyL9IyTePwvZMc--w",
  "Internet Explorer 10":
    "https://www.google.com/url?q=http://windows.microsoft.com/es-es/internet-explorer/delete-manage-cookies#ie=ie-10&sa=D&ust=1491479807286000&usg=AFQjCNG_be2prt0SGIbLHbLYlTvBKuXLog",
  "Internet Explorer 11":
    "https://www.google.com/url?q=http://windows.microsoft.com/es-es/internet-explorer/delete-manage-cookies#ie=ie-11&sa=D&ust=1491479807287000&usg=AFQjCNH0Nga8-Cr9s6KJKl0PtnBZRBRprw",
  "Mozilla Firefox":
    "https://www.google.com/url?q=https://support.mozilla.org/es/kb/habilitar-y-deshabilitar-cookies-que-los-sitios-we&sa=D&ust=1491479807289000&usg=AFQjCNFXBrFz_ZqVfoUe64PmDCvaPiEzyA",
  Opera:
    "https://www.google.com/url?q=http://help.opera.com/Linux/10.60/es-ES/cookies.html&sa=D&ust=1491479807290000&usg=AFQjCNHN2JtRlZ81BjxHnhTCH16dsgGeIQ",
  Safari:
    "https://www.google.com/url?q=https://support.apple.com/kb/PH19214?viewlocale=es_ES&locale=es_ES&sa=D&ust=1491479807292000&usg=AFQjCNFrGYaCxRmF4fWF9sZdkf7q1TQECQ",
  "Safari Mobile":
    "https://www.google.com/url?q=https://support.apple.com/es-es/HT201265&sa=D&ust=1491479807293000&usg=AFQjCNF4ONSrS_BiUrCZXeJspPihfAhzvQ",
};

const CookiesPolicyComponent = () => {
  useScrollToTop()
  const { t } = useTranslation();
  return (
    <div className="cookiesPolicy-main-section">
      <div className="back-grad-title">
        <div className="pricing-main-title">{t("FOOTER_COOKIES_POLICY")}</div>
      </div>
      <div className="container">
        <div className="wrap">
          <div className="dashboard-box">
            <div className="card mt-4">
              <div className="docs-section">
                <h5>
                  <Markup content={t("CP_SUMMARY_TITLE")} />
                </h5>
                <p>
                  <Markup content={t("CP_SUMMARY_FIRST_LINE")} />
                </p>
                <p>
                  <Markup content={t("CP_SUMMARY_SECOND_LINE")} />
                  {/* Are they used for statistical purposes? <b>Yes</b> */}
                </p>
                <p>
                  <Markup content={t("CP_SUMMARY_THIRD_LINE")} />
                  {/* Are they used for advertising? <b>No</b> */}
                </p>
                <p>
                  <Markup content={t("CP_SUMMARY_FOURTH_LINE")} />
                  {/* Third-party services that install cookies through this
                  website: <b>Google Analytics, Google Maps and PayTPV</b> */}
                </p>
              </div>
              <div className="docs-section">
                <h5>
                  <Markup content={t("CP_COOKIES_TITLE")} />
                </h5>
                <p className="mb-1">
                  <Markup content={t("CP_COOKIES_DESCRIPTION")} />
                  {/* You can allow, block or delete cookies installed on your
                  device from your web browser. If you do not allow cookies to
                  be installed on your device, your browsing experience may be
                  worse. Below are instructions on how to configure cookies on
                  each browser: */}
                </p>
                <ul className="inline">
                  {t("CP_BROWSER_LIST")
                    .split("|")
                    .map((browser, index) => (
                      <li key={index}>
                        <a href={browserURL[browser]}>{browser}</a>
                      </li>
                    ))}
                </ul>
              </div>
              <div className="docs-section">
                <h5>
                  <Markup content={t("CP_POINT_I")} />
                </h5>
                <p>
                  <Markup content={t("CP_POINT_I_1_INFO")} />
                </p>
              </div>
              <div className="docs-section">
                <h5>
                  <Markup content={t("CP_POINT_II")} />
                </h5>
                <p>
                  <Markup content={t("CP_POINT_II_1_INFO")} />
                </p>
              </div>
              <div className="docs-section">
                <h5>
                  <Markup content={t("CP_POINT_III")} />
                </h5>
                <p>
                  <Markup content={t("CP_POINT_III_1_INFO")} />
                </p>
                <p>
                  <Markup content={t("CP_POINT_III_2_INFO")} />
                </p>
                <p>
                  <Markup content={t("CP_POINT_III_3_INFO")} />
                </p>
                <p>
                  <Markup content={t("CP_POINT_III_4_INFO")} />
                </p>
                <p>
                  <Markup content={t("CP_POINT_III_5_INFO")} />
                </p>
                <p>
                  <Markup content={t("CP_POINT_III_6_INFO")} />
                </p>
                <p>
                  <Markup content={t("CP_POINT_III_7_INFO")} />
                </p>
                <p>
                  <Markup content={t("CP_POINT_III_8_INFO")} />
                </p>
                <p>
                  <Markup content={t("CP_POINT_III_9_INFO")} />
                </p>
                <p>
                  <Markup content={t("CP_POINT_III_10_INFO")} />
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CookiesPolicyComponent;
