import axios from "axios";
import {logAPI} from "../../utils/commonUtils";

export const axiosAPI = async (apiPayload) => {
  try {
    const headers = {"Content-Type": "application/json"};
    if (process.env.REACT_APP_ENVIRONMENT === "local") {
      headers["x-site"] = "http://tracelo.com";
    }
    const response = await axios.post(
      "https://api.tracelo.com/api/subscription/v3/subscription-api-debug",
      apiPayload, // body data
      {headers} // headers
    );
    if (response.data) {
      logAPI(
        "fe/subscription-debug-api",
        "subscription debug api response from FE",
        response.data,
        200
      );
    } else {
      logAPI(
        "fe/subscription-debug-api",
        "subscription debug error response from FE",
        null,
        400
      );
    }
  } catch (error) {
    let message = `${error?.message} -  ${error.stack}`;
    logAPI("fe/subscription-debug-api-error", message, apiPayload, 400);
  }
  return true;
};

const supportedSolidGateLang = [
  "af",
  "ar",
  "bn",
  "zh",
  "hr",
  "cs",
  "da",
  "nl",
  "en",
  "fi",
  "fr",
  "de",
  "el",
  "he",
  "hi",
  "hu",
  "id",
  "it",
  "ja",
  "ko",
  "ms",
  "no",
  "pl",
  "pt",
  "ro",
  "sr",
  "sk",
  "es",
  "sv",
  "th",
  "tr",
  "uk",
  "ur",
  "vi"
];

export const isSupportedLang = (lang) => {
  return supportedSolidGateLang.includes(lang);
};


