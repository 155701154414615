import React, {useEffect, useState} from "react";
import ReactiveSolidGateModalComponent from "./reactiveSolidGateModal";
import {connect} from "react-redux";
import {solidGateUpdateMethodIntent} from "../../../../redux/slice/solidGate/solidGateUpdateMethodIntentSlice";
import Notify from "../../../common/Notify/notify";

const ReactiveSolidGateModal = ({
  handlePaymentUpdate,
  productList,
  // API props
  createSolidGateIntent
}) => {
  const [merchantData, setMerchantData] = useState({
    merchant: "",
    paymentIntent: "",
    signature: ""
  });

  // Create Intent for loading SolidGate checkout page
  const createIntent = async () => {
    const res = await createSolidGateIntent();
    if (res.type === "solidGateUpdateMethodIntent/fulfilled") {
      const {merchant, paymentIntent, signature} = res?.payload?.data;
      setMerchantData({merchant, paymentIntent, signature});
    } else {
      Notify("error", res?.error?.message, "");
    }
  };

  useEffect(() => {
    createIntent();
  // eslint-disable-next-line
  }, []);

  return (
    <ReactiveSolidGateModalComponent
      handlePaymentUpdate={handlePaymentUpdate}
      productList={productList}
      merchantData={merchantData}
      createIntent={createIntent}
    />
  );
};

const mapStateToProps = (state) => {
  return {
    productList: state.getProductList.productListData
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    createSolidGateIntent: () => dispatch(solidGateUpdateMethodIntent())
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ReactiveSolidGateModal);
