import { Spin } from "antd";
import React, { useCallback, useState } from "react";
import "./reactiveSolidGateModal.scss";
import Notify from "../../../common/Notify/notify";
import { Cookies } from "react-cookie";
import { useTranslation } from "react-i18next";
import Payment from "@solidgate/react-sdk";
import { useDispatch } from "react-redux";
import { solidGateUpdatePaymentMethod } from "../../../../redux/slice/solidGate/solidGateUpdatePaymentMethodSlice";
import DummyLayout from "./dummyLayout/dummyLayout";
import { isSupportedLang } from "../../../signUpSolidGate/helpers";

const ReactiveSolidGateModalComponent = ({
  handlePaymentUpdate,
  productList,
  merchantData,
  createIntent
}) => {
  const cookies = new Cookies();
  const disPatch = useDispatch();
  const lang = cookies.get("lang");
  const cur = cookies.get("currency");
  const { t } = useTranslation();
  const subscriptionProduct = productList?.data.find(
    (item) => item.title === "standard"
  );
  const subscriptionProductPrice = subscriptionProduct?.currency_options.find(
    (item) => item.parameter === cur
  );
  const [solidGateError, setSolidGateError] = useState("");
  const [isLoading, setIsLoading] = useState("");
  const [isMounted, setIsMounted] = useState(false);
  const [form, setForm] = useState(null);
  const [cardType, setCardType] = useState("");

  const ErrorMessage = {
    0.01: t("GENERAL_DECLINE_0.01"),
    0.02: t("ORDER_EXPIRED_0.02"),
    0.03: t("ILLEGAL_OPERATION_0.03"),
    2.06: t("INVALID_CVV2_CODE_2.06"),
    2.08: t("INVALID_CARD_NUMBER_2.08"),
    2.09: t("INVALID_EXPIRATION_DATE_2.09"),
    2.1: t("INVALID_3DS_FLOW_ON_MERCHANT_SIDE_2.1"),
    3.01: t("CARD_BLOCKED_3.01"),
    3.02: t("INSUFFICIENT_FUNDS_3.02"),
    3.03: t("PAYMENT_AMOUNT_LIMIT_EXCESS_3.03"),
    3.04: t("TRANSACTION_DECLINED_BY_ISSUER_3.04"),
    3.05: t("CALL_YOUR_BANK_3.05"),
    3.06: t("DEBIT_CARD_NOT_SUPPORTED_3.06"),
    3.07: t("CARD_BRAND_NOT_SUPPORTED_3.07"),
    3.08: t("DO_NOT_HONOR_3.08"),
    3.1: t("SUSPECTED_FRAUD_3.1"),
    4.02: t("STOLEN_CARD_4.02"),
    4.04: t("LOST_CARD_4.04"),
    4.05: t("PSP_ANTIFRAUD_4.05"),
    4.07: t("TRUSTED_ANTIFRAUD_SYSTEM_4.07"),
    5.01: t("THREE_D_SECURE_VERIFICATION_FAILED_5.01"),
    5.02: t("INVALID_CARD_TOKEN_5.02"),
    5.03: t("APPLICATION_ERROR_5.03"),
    5.04: t("MERCHANT_NOT_CONFIGURED_CORRECTLY_5.04"),
    5.08: t("INVALID_TRANSACTION_5.08"),
    6.02: t("CONNECTION_ERROR_6.02"),
    7.01: t("CARD_TOKEN_NOT_FOUND_7.01")
  };

  const handleOnReadyPaymentInstance = useCallback((form) => {
    setForm(form);
  }, []);

  const handleMounted = useCallback(() => {
    setIsMounted(true);
  }, []);

  const handleCard = (e) => {
    setCardType(e?.card?.brand);
    console.log("card Brand ===>", e?.card?.brand);
    console.log("handleCard", e);
  };

  const handleOnSuccess = async (e) => {
    console.log("handleOnSuccess", e);
  };

  const handleOnFail = async (e) => {
    try {
      console.log("handleOnFail", e);
      setIsLoading(true);
      await createIntent();
      const error = isSupportedLang(lang)
        ? ErrorMessage[e.code] || e.message
        : e.message;
      setSolidGateError(error || t("PR_SOMETHING_WENT_WRONG"));
    } catch (error) {
      console.error("Error in handleOnFail:", error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleOrderStatus = async (e) => {
    console.log("handleOrderStatus", e);
    if (e.response.order.status === "auth_ok") {
      setIsLoading(true);
      try {
        const { transactions } = e.response;
        const firstTransaction = Object.values(transactions)?.[0] || {};
        const { card_token } = firstTransaction || {};
        const result = await disPatch(
          solidGateUpdatePaymentMethod({ token: card_token?.token })
        );
        console.log("result", result);
        if (result.type === "solidGateUpdatePaymentMethod/fulfilled") {
          Notify("success", result.payload.message, "");
          handlePaymentUpdate();
          window.scrollTo({
            top: 0,
            behavior: "smooth"
          });
        } else {
          Notify("error", result.error.message, "");
          handlePaymentUpdate();
        }
      } catch (error) {
        Notify("error", "An unexpected error occurred.", "");
      } finally {
        setIsLoading(false);
      }
    }
  };

  const handleOnError = (e) => {
    console.log("handleOnError", e);
  };

  const handleOnInteraction = (e) => {
    console.log("handleOnInteraction", e);
  };
  const handleOnVerify = (e) => {
    console.log("handleOnVerify", e);
  };
  const customStyles = {
    card_cvv: {
      'span.tooltip-icon' : {
        'display': 'none',
      }
    },
  };
  return (
    <Spin spinning={isLoading}>
      <div className="mpo_modal_wrapper">
        <div className="mpo_modal_content">
          <h4>{t("UPDATE_PAYMENT_METHOD")} SolidGate</h4>
          <p>{t("UPDATE_PAYMENT_METHOD_DESC")}</p>
        </div>
        <div className="mpo_modal_form">
          <div className="transaction__popup">
            <div className="main_container">
              <div className="transaction_bg_wrap">
                <div className="transaction_form">
                  <form action="/">
                    <div className="form-container-payment solidGate_method">
                      <div style={{ display: isLoading && "none" }}>
                        {Object.values(merchantData).every(
                          (value) => value
                        ) && (
                            <Payment
                              merchantData={merchantData}
                              formParams={{
                                allowSubmit: false
                              }}
                              onReadyPaymentInstance={
                                handleOnReadyPaymentInstance
                              }
                              onMounted={handleMounted}
                              width={"100%"}
                              onInteraction={handleOnInteraction}
                              onFail={handleOnFail}
                              onOrderStatus={handleOrderStatus}
                              onSuccess={handleOnSuccess}
                              onSubmit={() => {
                                setSolidGateError("");
                                setIsLoading(true);
                              }}
                              onError={handleOnError}
                              onCard={handleCard}
                              onVerify={handleOnVerify}
                              onPaymentDetails={(e) =>
                                console.log("onPaymentDetails", e)
                              }
                              styles={customStyles}
                            />
                          )}
                      </div>
                      {isLoading && <DummyLayout cardType={cardType} />}
                    </div>
                    {solidGateError && (
                      <p className="stripe_error">{solidGateError}</p>
                    )}
                    {isMounted && (
                      <div className="submit_button">
                        <button
                          className="payment__Submit"
                          type="button"
                          onClick={() => {
                            form?.submit();
                          }}
                        >
                          {t("REACTIVATE")}
                        </button>
                      </div>
                    )}
                    <div className="subscription_content_wrap">
                      <p>
                        {t("UPDATE_PAYMENT_METHOD_CHARGE_DESC", {
                          standard_price:
                            subscriptionProductPrice?.symbol +
                            subscriptionProductPrice?.amount
                        })}
                      </p>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Spin>
  );
};

export default ReactiveSolidGateModalComponent;
