import React, { useEffect, useState } from "react";
import { Link, useNavigate, NavLink } from "react-router-dom";
import { Dropdown, Space } from "antd";
import { HiMenu } from "react-icons/hi";
import mainLogo from "../../assets/header/Dark-Logo.svg";
import { TbLogout } from "react-icons/tb";
import { RiAdminFill } from "react-icons/ri";
import { FaSms } from "react-icons/fa";
import { MdPriceChange } from "react-icons/md";
import locateSvg from "../../assets/header/settings.svg";
import { AiFillSetting } from "react-icons/ai";
import { MailOutlined } from "@ant-design/icons";
import { t } from "i18next";
import { Cookies, useCookies } from "react-cookie";
import { languages } from "../../utils/commonUtils";
import ReactCountryFlag from "react-country-flag";
import { connect, useSelector } from "react-redux";
import { updateUserLanguage } from "../../redux/slice/updateUserLanguageSlice";
import LanguageModal from "../common/languageModal";
import i18n from "../../language";

const cookies = new Cookies();

const DashboardHeader = ({ callUpdateUserLanguage }) => {
  const authenticate = useSelector(
    (state) => state.authenticate?.authenticateData?.data?.language
  );
  const [lang, setLang] = useState(
    authenticate ? authenticate : cookies.get("lang")
  );
  const [flag, setFlag] = useState(cookies.get("langlogo") || "gb");
  const [isModalOpen, setIsModalOpen] = useState(false);

  const navigate = useNavigate();
  const langCookies = cookies.get("lang");
  const role = cookies.get("role");
  const [allCookies, , removeCookie] = useCookies(["token", "role"]);
  const superAdmin =
    useSelector(
      (state) => state?.authenticate?.authenticateData?.data?.last_name
    ) === "super_admin";
  useEffect(() => {
    const selectedLang = languages.find((item) => item.code === lang);
    if (selectedLang) {
      setFlag(selectedLang.flagCode);
      cookies.set("langlogo", selectedLang.flagCode, {
        path: "/",
        sameSite: true,
      });
    }
  }, [lang]);

  useEffect(() => {
    setLang(langCookies);
  }, [langCookies]);

  const updateLang = async () => {
    await callUpdateUserLanguage({
      language: lang,
    });
  };
  useEffect(() => {
    lang && updateLang();
  // eslint-disable-next-line
  }, [lang]);

  useEffect(() => {
    document.body.classList.toggle("no-scroll", isModalOpen);
    return () => document.body.classList.remove("no-scroll");
  }, [isModalOpen]);

  const handleChangeLanguage = (selectedLang, flagCode) => {
    i18n.changeLanguage(selectedLang);
    const newPath = window.location.pathname.split("/")[2]
      ? `/${selectedLang}/${window.location.pathname.split("/")[2]}`
      : `/${selectedLang}`;
    navigate(newPath);
    setIsModalOpen(false);
    setLang(selectedLang);
    cookies.set("lang", selectedLang, { path: "/", sameSite: true });
    cookies.set("langlogo", flagCode, { path: "/", sameSite: true });
  };

  const handleLogout = () => {
    removeCookie("token", { path: "/" });
    removeCookie("role", { path: "/" });

    const cookieKeys = Object.keys(allCookies);
    cookieKeys.forEach((key) => {
      if (!["currency", "lang", "langlogo"].includes(key)) {
        removeCookie(key, { path: "/" });
        removeCookie(key);
      }
    });

    const currentPath = window.location.pathname;
    const lang = allCookies.lang || "en";
    const newPath = `/${lang}`;
    if (currentPath !== newPath) {
      window.location.reload(newPath);
    }
  };

  const menuItems = [
    {
      label: (
        <NavLink to={`/${lang}/dash`} activeClassName="active">
          <img src={locateSvg} alt="" width={20} height={20} />
          <span>{t("DASHBOARD")}</span>
        </NavLink>
      ),
      key: "0",
    },
    {
      label: (
        <NavLink to={`/${lang}/settings`} activeClassName="active">
          <AiFillSetting />
          <span>{t("FOOTER_ACCOUNT")}</span>
        </NavLink>
      ),
      key: "1",
    },
    ...(role === "admin"
      ? [
        {
          label: (
            <NavLink to={`/${lang}/email-test`} activeClassName="active">
              <MailOutlined />
              <span>Test email</span>
            </NavLink>
          ),
          key: "2",
        },
        {
          label: (
            <NavLink to={`/${lang}/currency`} activeClassName="active">
              <MdPriceChange />
              <span>{t("CURRENCIES")}</span>
            </NavLink>
          ),
          key: "3",
        },
        superAdmin && {
          label: (
            <NavLink to={`/${lang}/sms-config`} activeClassName="active">
              <FaSms />
              <span>SMS Config</span>
            </NavLink>
          ),
          key: "4",
        },
        {
          label: (
            <NavLink to={`/${lang}/admin`} activeClassName="active">
              <RiAdminFill />
              <span>{t("ADMINS")}</span>
            </NavLink>
          ),
          key: "5",
        }
      ]
      : []),
    {
      label: (
        <Link
          to="#"
          onClick={(e) => {
            e.preventDefault();
            handleLogout();
          }}
        >
          <TbLogout />
          <span>{t("LOGOUT")}</span>
        </Link>
      ),
      key: "7",
    },
  ];


  return (
    <div className="dashboard-header">
      <div className="main-logo-img">
        <Link to={`/${lang}/dash`}>
          <img src={mainLogo} alt="Main Logo" />
        </Link>
      </div>
      <div className="header-lang-dropdown">
        <li onClick={() => setIsModalOpen(true)}>
          <ReactCountryFlag countryCode={flag} svg />
        </li>
      </div>
      <div className="hum-burger-menu">
        <Dropdown
          menu={{ items: menuItems }}
          trigger={["click"]}
          placement={lang === "ar" || lang === "he" ? "bottomLeft" : "bottomRight"}
          overlayClassName="dashboard-header-menu-mobile"
        >
          <span>
            <Space>
              <HiMenu />
            </Space>
          </span>
        </Dropdown>
      </div>
      <LanguageModal
        isOpen={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        currentLang={lang}
        handleChangeLanguage={handleChangeLanguage}
      />
    </div>
  );
};

const mapDispatchToProps = (dispatch) => {
  return {
    callUpdateUserLanguage: (data) => dispatch(updateUserLanguage(data)),
  };
};

export default connect(null, mapDispatchToProps)(DashboardHeader);
