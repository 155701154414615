/* global gtag */
import React, { useCallback, useRef } from "react";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import Notify from "../../common/Notify/notify";
import { Cookies } from "react-cookie";
import { useNavigate } from "react-router-dom";
import { logAPI } from "../../../utils/commonUtils";
import axios from "axios";
import axiosRetry from "axios-retry";
import { useDispatch, useSelector } from "react-redux";
import Payment from "@solidgate/react-sdk";
import { isSupportedLang } from "../helpers";
import DummyLayout from "./dummyLayout";
import { solidGateIntentV2Create } from "../../../redux/slice/solidGate/solidGateIntentV2CreateSlice";
import { solidGateV2SubscriptionCreate } from "../../../redux/slice/solidGate/solidGateV2SubscriptionCreateSlice";


axiosRetry(axios, {
  retries: 3, // Number of retries
  retryDelay: (retryCount) => {
    return retryCount * 1000; // Retry after 1 second on first attempt, 2 seconds on second attempt, etc.
  },
  retryCondition: (error) => {
    return true;
  }
});

const CheckoutComponent = (props) => {
  const {
    isLoading,
    setIsLoading,
    data,
    TrialCurr,
    subscriptionProductPrice,
    subscription,
    page,
    callgclidData,
    gclid,
    callSettingBanIp,
    ipData,
    searchNumber,
    merchantData,
    intentCreateData,
    setMerchantData,
    handleCloseModal
  } = props;

  const { t } = useTranslation();
  const navigate = useNavigate();
  const disPatch = useDispatch();
  const cookie = new Cookies();
  const lang = cookie.get("lang");
  const cookieCountry = cookie.get("country_code");
  const params = cookie.get("param");
  const subscriptionPlan = window.location.pathname.includes("subscription");
  const pageVersion = page === "track" || page === "trace" ? "b" : "w";
  const userCountry = useSelector((state) => state.sendDetailsData?.getSendDetailsData?.data?.country) || cookieCountry;
  const userId = useSelector((state) => state.solidGateRegister?.solidGateRegister?.data?.user_id) || "";
  const [ip, setIp] = useState("");
  const [isGooglePayAvailable, setIsGooglePayAvailable] = useState(false);
  const [isApplePayAvailable, setIsApplePayAvailable] = React.useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [cardType, setCardType] = useState("");
  const [isMounted, setIsMounted] = useState(false);
  const [form, setForm] = useState(null);

  const ErrorMessage = {
    0.01: t("GENERAL_DECLINE_0.01"),
    0.02: t("ORDER_EXPIRED_0.02"),
    0.03: t("ILLEGAL_OPERATION_0.03"),
    2.06: t("INVALID_CVV2_CODE_2.06"),
    2.08: t("INVALID_CARD_NUMBER_2.08"),
    2.09: t("INVALID_EXPIRATION_DATE_2.09"),
    2.1: t("INVALID_3DS_FLOW_ON_MERCHANT_SIDE_2.1"),
    3.01: t("CARD_BLOCKED_3.01"),
    3.02: t("INSUFFICIENT_FUNDS_3.02"),
    3.03: t("PAYMENT_AMOUNT_LIMIT_EXCESS_3.03"),
    3.04: t("TRANSACTION_DECLINED_BY_ISSUER_3.04"),
    3.05: t("CALL_YOUR_BANK_3.05"),
    3.06: t("DEBIT_CARD_NOT_SUPPORTED_3.06"),
    3.07: t("CARD_BRAND_NOT_SUPPORTED_3.07"),
    3.08: t("DO_NOT_HONOR_3.08"),
    3.1: t("SUSPECTED_FRAUD_3.1"),
    4.02: t("STOLEN_CARD_4.02"),
    4.04: t("LOST_CARD_4.04"),
    4.05: t("PSP_ANTIFRAUD_4.05"),
    4.07: t("TRUSTED_ANTIFRAUD_SYSTEM_4.07"),
    5.01: t("THREE_D_SECURE_VERIFICATION_FAILED_5.01"),
    5.02: t("INVALID_CARD_TOKEN_5.02"),
    5.03: t("APPLICATION_ERROR_5.03"),
    5.04: t("MERCHANT_NOT_CONFIGURED_CORRECTLY_5.04"),
    5.08: t("INVALID_TRANSACTION_5.08"),
    6.02: t("CONNECTION_ERROR_6.02"),
    6.03: t("PROCESSING_ISSUE_6.03"),
    7.01: t("CARD_TOKEN_NOT_FOUND_7.01")
  };

  useEffect(() => {
    if (ipData && ipData.ip) {
      setIp(ipData.ip);
    }
  }, [ipData]);

  ////////////////////////////////////////////////////////////////

  const appleContainerRef = useRef(null);
  const googleContainerRef = useRef(null);

  const handleOnReadyPaymentInstance = useCallback((form) => {
    setForm(form);
  }, []);

  const handleMounted = useCallback((event) => {
    setIsMounted(true);
    console.log('handleMounted', event);
    setIsGooglePayAvailable(event?.entity === "googlebtn");
    setIsApplePayAvailable(event?.entity === "applebtn");
  }, []);

  const handleOnError = (e) => {
    console.log("handleOnError", e);
  };
  const handleOnFail = async (e) => {
    try {
      console.log("handleOnFail", e);
      setIsLoading(true);
      const intentResponse = await disPatch(solidGateIntentV2Create(intentCreateData));
      if (intentResponse.type === "solidGateIntentV2Create/fulfilled") {
        const { paymentIntent, merchant, signature } =
          intentResponse.payload.data || {};
        setMerchantData({
          merchant: merchant || "",
          paymentIntent: paymentIntent || "",
          signature: signature || ""
        });
      }
      const error = isSupportedLang(lang)
        ? ErrorMessage[e.code] || e.message
        : e.message;
      setErrorMessage(error || t('PR_SOMETHING_WENT_WRONG'));
    } catch (error) {
      console.error("Error in handleOnFail:", error);
    } finally {
      setIsLoading(false);
    }
  };


  const handleOrderStatus = (e) => {
    if (e.response.order.status === "approved") {
      console.log('orderStatus', e)
      const { order, transactions } = e.response;
      const firstTransaction = Object.values(transactions)?.[0] || {};
      const { id: transactionId, card } = firstTransaction || {};
      gtag("event", "purchase", {
        transaction_id: transactionId,
        user_id: userId,
        page_version: pageVersion,
        purchase_amount: window.location.pathname.includes("subscription")
          ? subscriptionProductPrice?.amount
          : TrialCurr?.amount,
        currency: order?.currency?.toUpperCase(),
        email: data.email?.toLowerCase(),
        payment_method: card?.card_id,
        payment_details: [firstTransaction],
        postal_code: "", // pending
        country: userCountry || "",
      });
      if (!subscriptionPlan) {
        if (window.zaraz) {
          window.zaraz.track("purchase", {
            email: data.email?.toLowerCase(),
            user_id: userId,
            value: TrialCurr?.amount,
            currency_code: order?.currency?.toUpperCase(),
            transaction_id: transactionId,
            postal_code: "", // pending
            country: userCountry || "",
          });
        }
      }
      gtag("event", "conversion", {
        send_to: "AW-11387801114/zsDDCPmr7O8YEJqckLYq",
        value: subscriptionProductPrice.amount,
        currency: order?.currency?.toUpperCase(),
        transaction_id: transactionId,
        email: data.email?.toLowerCase(),
        postal_code: "", // pending
        country: userCountry || ""
      });
    } else {
      console.log("handleOrderStatus", e)
    }
  };

  const handleOnSuccess = async (e) => {
    try {
      setIsLoading(true);
      // const apiPayload = {
      //   ip_address: ip,
      //   country: userCountry,
      //   price: solidGateProductId,
      //   order_id: e.order.order_id,
      //   email: e.order.customer_email,
      //   user_platform: isMobile ? "MOB" : "WEB",
      //   subscription_amount: subscriptionProductPrice?.amount,
      //   // second_trial_sent_amount: Number(TrialCurr?.trial?.second_sent_amount),
      // };
      const apiV2Payload = {
        country: userCountry,
        order_id: e.order.order_id,
        email: e.order.customer_email,
        subscription_amount: subscriptionProductPrice?.amount,
      };
      logAPI("fe/stripe/confirm", "confirm api complete from FE", { ...apiV2Payload, ip: ip }, 200);

      const subscriptionResult = await disPatch(solidGateV2SubscriptionCreate(apiV2Payload));
      if (subscriptionResult.type === "solidGateV2SubscriptionCreate/fulfilled") {
        Notify("success", t("NOTIFY_STANDARD_SUBSCRIPTION"), "");
        const { token, role, email } = subscriptionResult.payload.data || {};
        if (
          window.location.pathname.includes("track") ||
          window.location.pathname.includes("trace")
        ) {
          if (
            params &&
            typeof params === "object" &&
            !Object.entries(params)
            .map(([key, value]) => {
              if (key === "off") {
                return "off";
              }else{
                return undefined
              }
            })
            .includes("off")
          ) {
            cookie.set("banned", "true");
            const data = {
              ip_address: ip,
              status: false,
            };
            await callSettingBanIp(data);
          }
        }
        if (gclid && gclid.length > 0 && gclid[0].key && gclid[0].value) {
          const gclidData = {
            email: data.email?.toLowerCase(),
            key: gclid[0].key,
            value: gclid[0].value,
            signup: "on",
            ip_address: ip
          };
          callgclidData(gclidData);
        }
        cookie.set("token", token);
        cookie.set("role", role);
        cookie.set("email", email);
        if (searchNumber.D && searchNumber.n) {
          navigate(
            `/${lang}/dash?D=+${searchNumber?.D?.replaceAll(" ", "")}&n=${searchNumber.n
            }`
          );
        } else {
          navigate(`/${lang}/dash`);
        }
      } else {
        Notify("error", subscriptionResult.error.message, "");
        handleCloseModal()
      }
    } catch (error) {
      let errorMessage = `${JSON.stringify(error.message)} - ${JSON.stringify(
        error.stack
      )}`;
      logAPI("checkout-error", errorMessage, null, 400);
    } finally {
      setIsLoading(false)
    }
  };

  const handleCard = (e) => {
    setCardType(e?.card?.brand)
    console.log("card Brand ===>", e?.card?.brand);
  };
  const customStyles = {
    card_cvv: {
      'span.tooltip-icon' : {
        'display': 'none',
      }
    },
  };
  return (
    <div className="body-modal">
      <div className="payment-details payment_modal_area">
        <div className="df-details">
          <div className="left-payment">
            <p>{t("SIGNUP_PAYMENT_TITLE")}</p>
          </div>
          <div className="right-payment pay">
            <p>
              {subscription !== "standard"
                ? TrialCurr.symbol + TrialCurr.amount
                : TrialCurr.symbol + subscriptionProductPrice.amount}
            </p>
          </div>
        </div>
        <div className="right-payment pay">
          {!(page === "track" || page === "trace") && (
            <span>
              {page === "track" || page === "trace"
                ? t("TRIAL_TIME.B")
                : t("TRIAL_TIME", {
                  amount:
                    subscriptionProductPrice.symbol +
                    subscriptionProductPrice.amount
                })}
            </span>
          )}
        </div>
        {(page === "track" || page === "trace") && (
          <div className="time-span">
            <span>
              {page === "track" || page === "trace"
                ? t("TRIAL_TIME.B")
                : t("TRIAL_TIME", {
                  amount:
                    subscriptionProductPrice.symbol +
                    subscriptionProductPrice.amount
                })}
            </span>
          </div>
        )}
      </div>
      {/* <div ref={googleContainerRef} /> */}
      <div style={{ display: isLoading && "none" }}>
        <Payment
          googlePayButtonParams={{
            allowedAuthMethods: ['PAN_ONLY', 'CRYPTOGRAM_3DS'],
            enabled: true,
            color: "black",
            type: "pay",
          }}
          applePayButtonParams={{
            enabled: true,
            integrationType: 'js',
            color: "black",
            type: "check-out",
          }}
          paypalButtonParams={{}}
          googlePayContainerRef={googleContainerRef}
          applePayContainerRef={appleContainerRef}
          merchantData={merchantData}
          formParams={{
            allowSubmit: false,
            // submitButtonText: t("SIGNUP_SUBMIT")
          }}
          onReadyPaymentInstance={handleOnReadyPaymentInstance}
          onMounted={handleMounted}
          width={"100%"}
          onFail={handleOnFail}
          onOrderStatus={handleOrderStatus}
          onSuccess={handleOnSuccess}
          onSubmit={() => { setErrorMessage(""); setIsLoading(true) }}
          onError={handleOnError}
          onCard={handleCard}
          onPaymentDetails={(e) => console.log("onPaymentDetails", e)}
          styles={customStyles}
        />
      </div>
      {isLoading &&
        <DummyLayout
          isGooglePayAvailable={isGooglePayAvailable}
          isApplePayAvailable={isApplePayAvailable}
          cardType={cardType}
        />
      }
      {errorMessage &&
        <div id="payment-message" style={{ color: "red" }}>
          {errorMessage}
        </div>
      }
      {isMounted && (
        <button
          disabled={isLoading}
          id="submit"
          className="hl_cta_wrap"
          type="submit"
          style={{ width: "100%", marginTop: 12 }}
          onClick={() => {
            form?.submit();
          }}
        >
          <span id="button-text">{t("SIGNUP_SUBMIT")}</span>
        </button>
      )}
      {!(page === "track" || page === "trace") && (
        <span id="sign-up-term">
          {t("CHECKOUT_NOTE", {
            trialPrice: TrialCurr?.symbol + TrialCurr?.amount,
            subPrice:
              subscriptionProductPrice?.symbol +
              subscriptionProductPrice?.amount
          })}
        </span>
      )}
    </div>
  );
};

export default CheckoutComponent;
