import visaIconHolder from "../../../../../assets/checkout/visaIconHolder.png";
import mastercardIconHolder from "../../../../../assets/checkout/mastercardIconHolder.png";
import maestroIconHolder from "../../../../../assets/checkout/maestroIconHolder.png";
import jcbIconHolder from "../../../../../assets/checkout/jcbIconHolder.png";
import discoverIconHolder from "../../../../../assets/checkout/discoverIconHolder.png";
import unionpayIconHolder from "../../../../../assets/checkout/unionpayIconHolder.png";
import americanExpressIconHolder from "../../../../../assets/checkout/americanExpressIconHolder.png";
import dinersClubIconHolder from "../../../../../assets/checkout/dinersClubIconHolder.png";
import cartesBancairesIconHolder from "../../../../../assets/checkout/cartesBancairesIconHolder.png";
import defaultIconHolder from "../../../../../assets/checkout/defaultIconHolder.png";
import { Cookies } from "react-cookie";
import { t } from "i18next";
import { isSupportedLang } from "../../../../signUpSolidGate/helpers";
import "./dummyLayout.scss";

const DummyLayout = ({ cardType }) => {
  const cookie = new Cookies();
  const lang = cookie.get("lang");
  const isSupport = isSupportedLang(lang);
  const getCardBrandImage = (cardType) => {
    switch (cardType) {
      case "VISA":
        return visaIconHolder;
      case "MASTERCARD":
        return mastercardIconHolder;
      case "MAESTRO":
        return maestroIconHolder;
      case "JCB":
        return jcbIconHolder;
      case "DISCOVER":
        return discoverIconHolder;
      case "UNIONPAY":
        return unionpayIconHolder;
      case "AMERICAN EXPRESS":
        return americanExpressIconHolder;
      case "DINERS CLUB SHORT":
        return dinersClubIconHolder;
      case "CARTES BANCAIRES":
        return cartesBancairesIconHolder;
      default:
        return defaultIconHolder;
    }
  };

  const cardBrandImage = getCardBrandImage(cardType);

  return (
    <div className="reactive-dummy-layout">
      {/* Card Number Input */}
      <div className="reactive-input-group">
        <label className="reactive-input-label">
          {isSupport
            ? t("TS_CARD_NUMBER_TITLE")
            : "Credit or Debit Card Number"}
        </label>
        <input
          type="text"
          placeholder="XXXX XXXX XXXX XXXX"
          className="reactive-input-field"
        />
        {cardBrandImage && (
          <img
            src={cardBrandImage}
            alt={`${cardType} Logo`}
            className="reactive-card-brand"
            style={{ height: 50, width: 50 }}
          />
        )}
      </div>

      {/* Expiry Date and CVV Section */}
      <div className="reactive-expiry-cvv-section">
        {/* Expiry Date */}
        <div className="reactive-expiry-date">
          <label className="reactive-input-label">
            {isSupport ? t("TS_CARD_EXPIRY_DATE_TITLE") : "Expiry Date"}
          </label>
          <input
            type="text"
            placeholder={isSupport ? t("TS_CARD_MM_YY_TITLE") : "MM/YY"}
            className="reactive-input-field"
          />
        </div>
        {/* CVV */}
        <div className="reactive-cvv">
          <label className="reactive-input-label">
            {isSupport ? t("TS_CARD_CVC_CVV_TITLE") : "CVV/CVC"}
          </label>
          <input
            type="text"
            placeholder={isSupport ? t("TS_CARD_CVV_TITLE") : "CVV"}
            className="reactive-input-field"
          />
        </div>
      </div>
    </div>
  );
};

export default DummyLayout;
